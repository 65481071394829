/**************** intro_video css start ****************/
.intro_video_bg{
    @include background("../img/intro_video_bg.png");
    height: 550px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    @media #{$small_mobile}{
      height: 350px;
    }
    @media #{$large_mobile}{
      height: 350px;
    }
    @media #{$tab_device}{
      height: 350px;
    }
    @media #{$medium_device}{
    
    }
    h2{
        font-size: 60px;
        color: $white_color;
        @media #{$small_mobile}{
          font-size: 30px;
        }
        @media #{$large_mobile}{
          font-size: 30px;
        }
        @media #{$tab_device}{
          font-size: 40px;
        }
        @media #{$medium_device}{
        
        }
    }
    .intro_video_iner{
        width: 100%;
        height: 100%;
    }

    .intro_video_icon{
        display: inline-block;
        margin-top: 50px;
    }
    .video-play-button {
        position: absolute;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        box-sizing: content-box;
        display: block;
        width: 32px;
        height: 44px;
        /* background: #fa183d; */
        border-radius: 50%;
        padding: 18px 20px 18px 28px;
      }
      
      .video-play-button:before {
        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 80px;
        height: 80px;
        background: $base_color;
        border-radius: 50%;
        animation: pulse-border 1500ms ease-out infinite;
      }
      
      .video-play-button:after {
        content: "";
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 80px;
        height: 80px;
        background: $base_color;
        border-radius: 50%;
        transition: all 200ms;
      }
      
      .video-play-button:hover:after {
        background: rgba(255, 255, 255, .30);
      }
      
      .video-play-button img {
        position: relative;
        z-index: 3;
        max-width: 100%;
        width: auto;
        height: auto;
      }
      
      .video-play-button span {
        display: inline-block;
        position: relative;
        z-index: 3;
        top: 6px;
        cursor: pointer;
      }
      .ti-control-play:before {
        content: "\e6ad";
        font-size: 28px;
        color: #fff;
      }
      @keyframes pulse-border {
        0% {
          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
          opacity: 1;
        }
        100% {
          transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
          opacity: 0;
        }
      }
}