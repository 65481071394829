/**************** banner part css start ****************/
.banner_part{
    height: 1080px;
    position: relative;
    background-image: url(../img/banner_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media #{$tab}{
        height: 650px;
    }
    @media #{$medium_device}{
        height: 650px;
    }
    
    .banner_text{
        display: table;
        width: 100%;
        height: 1080px;
        padding-top: 40px;
        .banner_text_iner{
            display: table-cell;
            vertical-align: middle;
            
        }
        @media #{$tab}{
            text-align: center;
            padding-top: 0px;
            height: 700px;
        }

        @media #{$medium_device}{
            height: 650px;
        }
        h5{
            font-size: 18px;
            text-transform: capitalize;
            color: $base_color;
            font-weight: 400;
            font-family: $font_stack_1;
        }
        h1{
            font-size: 65px;
            font-weight: 400;
            margin-bottom: 15px;
            line-height: 84px;
            color: $white_color;
            margin: 20px 0 46px;
            @media #{$small_mobile}{
                font-size: 27px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$large_mobile}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$tab_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$medium_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }
            span{
                color: $btn_bg;
            }
        }
    }
}
  
/**************** hero part css end ****************/
